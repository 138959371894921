<template>
  <div style="text-align: center">
    <div v-if="loginIcon" class="loader"></div>
    <div v-if="noUser" style="margin-top: 140px">
      <el-image :src="require('/src/assets/image/malfunction.png')" style="width: 120px; height: 100px"/>
      <div style="font-size: 14px;color: #F56C6C;font-weight: bold;margin-top: 6px">程序出错啦 ~</div>
    </div>
  </div>
</template>

<script>
import { selectUser } from "@/api/org";
export default {
  name: "MiniLogin",
  data(){
    return{
      loginIcon:true,
      a:"",
      noUser:false,
    }
  },
  mounted(){
    this.login();
  },
  methods:{
    /**
     * 解析url中的参数值
     */
    getUrlData(url){
      let data = url.slice(url.indexOf('?') + 1);
      let pairs = data.split('&');
      let params = {};
      for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split('=');
        let key = decodeURIComponent(pair[0]);
        let value = decodeURIComponent(pair[1]);
        params[key] = value;
      }
      return params;
    },
    login: function () {

      let url = window.location.href;
      let urlData = this.getUrlData(url);
      if (urlData && urlData.type && urlData.type == "mini"){
        let user = {token:urlData.token};
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("userType", "mini");
        this.selectUser("/workspaceMini",urlData.token)
      }else {
        localStorage.setItem("userType", "pc");
        this.$router.replace('/login');
      }
    },
    selectUser(url,token){
      console.log("开始接口请求！！！！！！！")
      selectUser().then(val=>{
        this.loginIcon = false;
        if (val.status == 200 && val.data){
          val.data.token = token;
          localStorage.setItem("user", JSON.stringify(val.data));
          this.$router.replace(url);
        }else {
          this.noUser = true;
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/* From Uiverse.io by SchawnnahJ */
.loader {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  transform: rotate(165deg);
}

.loader:before, .loader:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.25em;
  transform: translate(-50%, -50%);
}

.loader:before {
  animation: before8 2s infinite;
}

.loader:after {
  animation: after6 2s infinite;
}

@keyframes before8 {
  0% {
    width: 0.5em;
    box-shadow: 1em -0.5em #F56C6C, -1em 0.5em #409EFF;
  }

  35% {
    width: 2.5em;
    box-shadow: 0 -0.5em #F56C6C, 0 0.5em #409EFF;
  }

  70% {
    width: 0.5em;
    box-shadow: -1em -0.5em #F56C6C, 1em 0.5em #409EFF;
  }

  100% {
    box-shadow: 1em -0.5em #F56C6C, -1em 0.5em #409EFF;
  }
}

@keyframes after6 {
  0% {
    height: 0.5em;
    box-shadow: 0.5em 1em #6cec2c, -0.5em -1em #ffd553;
  }

  35% {
    height: 2.5em;
    box-shadow: 0.5em 0 #6cec2c, -0.5em 0 #ffd553;
  }

  70% {
    height: 0.5em;
    box-shadow: 0.5em -1em #6cec2c, -0.5em 1em #ffd553;
  }

  100% {
    box-shadow: 0.5em 1em #6cec2c, -0.5em -1em #ffd553;
  }
}

.loader {
  position: absolute;
  top: calc(30% - 1.25em);
  left: calc(50% - 1.25em);
}
</style>
